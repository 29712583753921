export const LanguageEnum = {
  He: "he",
  En: "en",
} as const
export type LanguageEnum = (typeof LanguageEnum)[keyof typeof LanguageEnum]

/// /////////////////////////////////////////// From old project
export const UserStatusEnum = {
  Active: "ACTIVE",
  Marketing: "MARKETING",
  Authorized: "AUTHORIZED",
  Authenticated: "AUTHENTICATE",
  WaitingForAuthorization: "WAIFAU",
  WaitingForAuthentication: "WAIFAUTN",
} as const

export const PricingPlanEnum = {
  FullManagement: "FULL_MANAGEMENT",
  AlertsYearly: "ALERTS_YEARLY",
  AlertsMonthly: "ALERTS_MONTHLY",

  // Nov 2022 new plans
  FundsConsulting: "PRICE_PLAN_PORTFOLIO_CONSULTING",
  PortfolioManagement: "PRICE_PLAN_PORTFOLIO_MANAGEMENT",
  Stocks: "PRICE_PLAN_STOCKS",
} as const

export const ProdCodesEnum = {
  Stocks: "PRODUCT_STOCK",
  StocksManaged: "PRODUCT_STOCK_MANAGEMENT",
  Invest: "PRODUCT_INVEST",
  Fund: "PRODUCT_FUND",
  Consult: "PRODUCT_FUND_CONSULT",
  Pensions: "PRODUCT_PENSION",
  PensionsManaged: "PRODUCT_PENSION_MANAGEMENT",
} as const

export const UpcomingProdCodesEnum = {
  Fund: ProdCodesEnum.Fund,
  StockManagement: "PRODUCT_STOCK_MANAGEMENT",
} as const

export type UpcomingProdCodesEnum = (typeof UpcomingProdCodesEnum)[keyof typeof UpcomingProdCodesEnum]

export type ProdCodesEnum = (typeof ProdCodesEnum)[keyof typeof ProdCodesEnum]

export type ProdCodesUnion = ProdCodesEnum | UpcomingProdCodesEnum

export const RefundCodes = {
  CANCEL: "CANCEL_SUBSCRIPTION",
  REFUND: "REFUND_SALE",
} as const
export type RefundCodes = (typeof RefundCodes)[keyof typeof RefundCodes]

export const ShortPlanEnum = {
  consult: "PRICING_PLAN_CONSULT",
} as const

export const AssetsStatusEnum = {
  ActivePending: "AP",
  Active: "A",
  Pending: "P",
  InProcess: "PG",
} as const
